import React from 'react';
import { uniqueId } from 'lodash';
import { getLibelleImputation } from '@jvs-group/jvs-mairistem-finances-utils';
import { toast } from 'react-toastify';
import { importBiens } from '../../services/outil';
import type Simulation from '../../../Simulation/interfaces/simulation';
import type { ImportData } from './ImportTable';
import ImportTable from './ImportTable';

interface ImportBienProps {
  simulation: Simulation;
  onClose: () => void;
  onValidate: () => void;
}

const ImportBien = ({
  simulation,
  onClose,
  onValidate,
}: ImportBienProps) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [biens, setBiens] = React.useState<ImportData[]>([]);

  const handleLoadImportBiens = async () => {
    try {
      const biensData = await importBiens(simulation?.identifiant);
      const article68 : ImportData = {
        key: '68',
        libelle: "Constatation de l'amortissement des biens:",
        libelleDetail: 'dépense au compte 68',
        children: [],
        montant: 0,
        montantImputationComptable: 0,
        montantProposition: 0,
      };
      const article28 : ImportData = {
        key: '28',
        libelle: "Constatation de l'amortissement des biens:",
        libelleDetail: 'dépense au compte 28',
        children: [],
        montant: 0,
        montantImputationComptable: 0,
        montantProposition: 0,
      };
      const article139 : ImportData = {
        key: '139',
        libelle: "Constatation de l'amortissement des subventions perçues:",
        libelleDetail: 'dépense au compte 139',
        children: [],
        montant: 0,
        montantImputationComptable: 0,
        montantProposition: 0,
      };
      const article77 : ImportData = {
        key: '77',
        libelle: "Constatation de l'amortissement des subventions perçues:",
        libelleDetail: 'dépense au compte 77',
        children: [],
        montant: 0,
        montantImputationComptable: 0,
        montantProposition: 0,
      };

      biensData?.forEach((bien) => {
        const e = {
          key: uniqueId(),
          libelle: getLibelleImputation(
            bien.sens,
            bien.section,
            bien.chapitre,
            bien.article,
            bien?.operation,
            bien?.fonction,
            bien?.ventilation,
            bien?.analytique,
          ),
          montant: bien.montant,
          montantImputationComptable: bien.montantImputationComptable,
          montantProposition: bien.montantProposition,
          identifiantImputation: bien.identifiantImputation,
        };

        if (bien?.article?.startsWith('68')) {
          article68.children.push(e);
          article68.montant += bien.montant;
          article68.montantImputationComptable += bien.montantImputationComptable;
          article68.montantProposition += bien.montantProposition;
        } else if (bien?.article?.startsWith('28')) {
          article28.children.push(e);
          article28.montant += bien.montant;
          article28.montantImputationComptable += bien.montantImputationComptable;
          article28.montantProposition += bien.montantProposition;
        } else if (bien?.article?.startsWith('139')) {
          article139.children.push(e);
          article139.montant += bien.montant;
          article139.montantImputationComptable += bien.montantImputationComptable;
          article139.montantProposition += bien.montantProposition;
        } else if (bien?.article?.startsWith('77')) {
          article77.children.push(e);
          article77.montant += bien.montant;
          article77.montantImputationComptable += bien.montantImputationComptable;
          article77.montantProposition += bien.montantProposition;
        }
      });

      setBiens([
        article68,
        article28,
        article139,
        article77,
      ]);
    } catch (e) {
      toast.error('Erreur lors du chargement des biens');
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    handleLoadImportBiens();
  }, []);

  return (
    <ImportTable
      simulation={simulation}
      onClose={onClose}
      onValidate={onValidate}
      loading={loading}
      data={biens}
    />
  );
};

export default ImportBien;
