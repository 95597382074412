import FeuilleSaisieCode from '../../constants/feuilleSaisieCode';
import BaseFeuilleSaisie from './BaseFeuilleSaisie';
import ChapitreTypeRegroupement from '../Regroupement/ChapitreTypeRegroupement';
import ImputationTypeRegroupement from '../Regroupement/ImputationTypeRegroupement';
import SensTypeRegroupement from '../Regroupement/SensTypeRegroupement';
import FonctionTypeRegroupement from '../Regroupement/FonctionTypeRegroupement';
import AnalytiqueTypeRegroupement from '../Regroupement/AnalytiqueTypeRegroupement';
import VentilationTypeRegroupement from '../Regroupement/VentilationTypeRegroupement';
import ArticleTypeRegroupement from '../Regroupement/ArticleTypeRegroupement';
import TypeRegroupement from '../../constants/typeRegroupement';
import type Simulation from '../../../Simulation/interfaces/simulation';
import TypeBudget from '../../../Simulation/enums/typeBudget';

class FonctionnementFeuilleSaisie extends BaseFeuilleSaisie {
  constructor() {
    super();
    this.code = FeuilleSaisieCode.FONCTIONNEMENT;
    this.isCreditReporteShown = true;
    this.section = 'F';
    this.libelle = 'Fonctionnement';
    this.defaultCodeFinArbo = TypeRegroupement.ARTICLE;
    this.nodes = [
      [new SensTypeRegroupement()],
      [new ChapitreTypeRegroupement()],
      [new ArticleTypeRegroupement()],
      [new FonctionTypeRegroupement()],
      [new AnalytiqueTypeRegroupement()],
      [new VentilationTypeRegroupement(), new ImputationTypeRegroupement()],
    ];

    this.icon = {
      iconSet: 'Lucide',
      name: 'BatteryCharging',
    };
  }

  isVisible(simulation: Simulation): boolean {
    return simulation?.budget?.type !== TypeBudget.COMPTE_ADMINISTRATIF || simulation?.exercice?.budReportFonc;
  }
}

export default FonctionnementFeuilleSaisie;
