import React from 'react';
import {
  Button,
  Dimmer,
  Form,
  Loader,
  Modal,
} from '@jvs-group/jvs-mairistem-composants';
import {
  AnalytiqueSearchInput,
  ArticleSearchInput, ChapitreSearchInput, FonctionSearchInput, OperationSearchInput, VentilationSearchInput,
  type Imputation,
} from '@jvs-group/jvs-mairistem-comptabilite';
import { isNil } from 'lodash';
import { toast } from 'react-toastify';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import type { OutilModalData } from '../../../Simulation/components/ImputationTable';
import { getRowFilters } from '../../../../utils/treeView';
import type Simulation from '../../../Simulation/interfaces/simulation';
import { checkImputation, type CreateImputation } from '../../../../utils/imputation';
import { createImputation } from '../../services/imputation';

const optionSection = [
  {
    key: 'F',
    text: 'Fonctionnement',
    value: 'F',
  },
  {
    key: 'I',
    text: 'Investissement',
    value: 'I',
  },
];

const optionSens = [
  {
    key: 'D',
    text: 'Dépense',
    value: 'D',
  },
  {
    key: 'R',
    text: 'Recette',
    value: 'R',
  },
];

interface CreationImputationModalProps {
  data: OutilModalData;
  onClose: () => void;
  onValidate: (imputation: Imputation) => void;
  open: boolean;
  simulation: Simulation;
}

const CreationImputationModal = ({
  data,
  onClose,
  onValidate,
  open,
  simulation,
}: CreationImputationModalProps) => {
  const [modalLoading, setModalLoading] = React.useState<boolean>(true);
  const [imputationData, setImputationData] = React.useState<CreateImputation>();

  React.useEffect(() => {
    setModalLoading(false);
    setImputationData({
      section: data?.feuilleSaisie?.section,
      ...getRowFilters(data.rowData, data?.filters, data?.feuilleSaisie, true),
    });
  }, []);

  const handleChangeImputation = (name, item, libelle) => {
    setImputationData((old) => ({
      ...old,
      [name]: item?.code,
      [libelle]: item?.libelle,
    }));
  };

  const handleChangeImputationChapitre = (name, item) => {
    setImputationData((old) => ({
      ...old,
      [name]: item?.code,
      cha_libelle: item?.libelle,
      codeArticle: null,
      art_libelle: null,
    }));
  };

  const handleCreateImputation = async () => {
    try {
      checkImputation(imputationData);

      try {
        const imputatiton = await createImputation(simulation?.identifiant, imputationData);
        onValidate?.(imputatiton);
      } catch (e) {
        toast.error(getErrorMessage(e, "Erreur lors de la création de l'imputation"));
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleDropdownChange = (e, { name, value }) => {
    setImputationData((old) => ({
      ...old,
      [name]: value,
    }));
  };

  return (
    <Modal
      closeIcon
      onClose={onClose}
      open={open}
    >
      <Modal.Header content={'Création d\'imputation'} />
      <Modal.Content>
        { modalLoading ? (
          <Dimmer active inverted>
            <Loader size="massive" />
          </Dimmer>
        ) : (
          <Form>
            <Form.Select
              label="Sens"
              name="sens"
              onChange={handleDropdownChange}
              options={optionSens}
              value={imputationData?.sens}
              required
            />

            <Form.Select
              label="Section"
              name="section"
              onChange={handleDropdownChange}
              options={optionSection}
              value={imputationData?.section}
              required
            />

            <Form.Field
              control={ChapitreSearchInput}
              defaultSearchQuery={
                !isNil(imputationData?.codeChapitre)
                  ? `${imputationData?.codeChapitre} - ${imputationData?.cha_libelle}` : ''
                }
              identifiantExercice={simulation.identifiantExercice}
              menuPortalTarget={document.body}
              section={imputationData?.section}
              placeholder="Chapitre"
              name="codeChapitre"
              label="Chapitre"
              onSelect={(name, item) => handleChangeImputationChapitre(name, item)}
              required
            />

            <Form.Field
              control={ArticleSearchInput}
              defaultSearchQuery={
                !isNil(imputationData?.codeArticle)
                  // eslint-disable-next-line max-len
                  ? `${imputationData?.codeArticle} - ${imputationData?.art_libelle} - (${imputationData?.codeChapitre})` : ''
              }
              value={imputationData?.codeArticle}
              identifiantExercice={simulation.identifiantExercice}
              menuPortalTarget={document.body}
              section={imputationData?.section}
              placeholder="Article"
              name="codeArticle"
              label="Article"
              sens={imputationData?.sens}
              codeChapitre={imputationData?.codeChapitre}
              onSelect={(name, item) => handleChangeImputation(name, item, 'art_libelle')}
              required
            />

            {simulation?.exercice?.gestionFonctionnelle && (
            <Form.Field
              control={FonctionSearchInput}
              defaultSearchQuery={
                !isNil(imputationData?.codeFonction)
                  ? `${imputationData?.codeFonction} - ${imputationData?.fon_libelle}` : ''
              }
              identifiantExercice={simulation.identifiantExercice}
              menuPortalTarget={document.body}
              section={imputationData?.section}
              placeholder="Fonction"
              name="codeFonction"
              label="Fonction"
              codeChapitre={imputationData?.codeFonction}
              onSelect={(name, item) => handleChangeImputation(name, item, 'fon_libelle')}
            />
            )}

            {imputationData?.section !== 'F' && (
              <Form.Field
                control={OperationSearchInput}
                defaultSearchQuery={
                  !isNil(imputationData?.codeOperation)
                    // eslint-disable-next-line max-len
                    ? `${imputationData?.codeOperation} - ${imputationData?.ope_libelle}` : ''
                }
                identifiantExercice={simulation.identifiantExercice}
                menuPortalTarget={document.body}
                section={imputationData?.section}
                placeholder="Opération"
                name="codeOperation"
                label="Opération"
                codeChapitre={imputationData?.codeOperation}
                onSelect={(name, item) => handleChangeImputation(name, item, 'ope_libelle')}
              />
            )}

            {simulation?.exercice?.gestionVentilation && !simulation?.exercice.venExec
              && (
              <Form.Field
                control={VentilationSearchInput}
                defaultSearchQuery={
                  !isNil(imputationData?.codeVentilation)
                    // eslint-disable-next-line max-len
                    ? `${imputationData?.codeVentilation} - ${imputationData?.ven_libelle}` : ''
                }
                identifiantExercice={simulation.identifiantExercice}
                menuPortalTarget={document.body}
                section={imputationData?.section}
                placeholder="Ventilation"
                name="codeVentilation"
                label="Ventilation"
                codeChapitre={imputationData?.codeVentilation}
                onSelect={(name, item) => handleChangeImputation(name, item, 'ven_libelle')}
              />
              )}

            {simulation?.exercice?.gestionAnalytique && !simulation?.exercice.anaExec
            && (
            <Form.Field
              control={AnalytiqueSearchInput}
              defaultSearchQuery={
                !isNil(imputationData?.codeAnalytique)
                  ? `${imputationData?.codeAnalytique} - ${imputationData?.ana_libelle}` : ''
              }
              identifiantExercice={simulation.identifiantExercice}
              menuPortalTarget={document.body}
              section={imputationData?.section}
              placeholder="Analytique"
              name="codeAnalytique"
              label="Analytique"
              codeChapitre={imputationData?.codeAnalytique}
              onSelect={(name, item) => handleChangeImputation(name, item, 'ana_libelle')}
            />
            )}
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Annuler"
          data-testid="closeButton"
          icon="x"
          onClick={onClose}
          size="tiny"
        />
        <Button
          content="Créer l'imputation"
          data-testid="confirmButton"
          onClick={handleCreateImputation}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default CreationImputationModal;
