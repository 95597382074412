import React from 'react';
import { Modal } from '@jvs-group/jvs-mairistem-composants';
import type OutilModalProps from '../../interfaces/OutilModalProps';
import ImportExportExcelMenu from './ImportExportExcelMenu';

const ImportExportExcelModal = ({
  onClose,
  onValidate,
  open,
  simulation,
}: OutilModalProps) => {
  const handleClose = () => onClose?.();

  return (
    <Modal
      closeIcon
      onClose={handleClose}
      open={open}
    >
      <Modal.Header content="Exporter et importer au format Excel" />
      <Modal.Content>
        <ImportExportExcelMenu onValidate={onValidate} simulation={simulation} />
      </Modal.Content>
    </Modal>
  );
};

export default ImportExportExcelModal;
