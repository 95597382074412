import React from 'react';
import {
  Currency,
  Form,
  type CheckboxProps,
} from '@jvs-group/jvs-mairistem-composants';

interface CalculPropositionModalCheckboxInputProps {
  checkbox: CheckboxProps;
  currency: object;
  testid: string;
}

const CalculPropositionModalCheckboxInput = ({
  checkbox,
  currency,
  testid,
}: CalculPropositionModalCheckboxInputProps) => (
  <Form.Group className="majorationMinorationGroup" data-testid={testid}>
    <Form.Checkbox width={8} {...checkbox} />
    <Form.Field
      control={Currency}
      width={6}
      {...currency}
    />
  </Form.Group>
);

export default CalculPropositionModalCheckboxInput;
