import React from 'react';
import { Icon } from '@jvs-group/jvs-mairistem-composants';
import type BaseFeuilleSaisie from '../entities/FeuilleSaisie/classes/FeuilleSaisie/BaseFeuilleSaisie';
import TypeRegroupement from '../entities/FeuilleSaisie/constants/typeRegroupement';
import type { TreeRow } from '../entities/FeuilleSaisie/interfaces/treeRow';
import type Filters from '../entities/Simulation/interfaces/filters';

export enum TreeSymbol {
  DEPTH = 'treeDepth',
  PARENT = 'parent',
}

export const getSymbol = (data: TreeRow, symbolName: TreeSymbol) => data?.[Object.getOwnPropertySymbols(data).find(
  (s) => s.description === symbolName,
)];

export const getRowFilters = (
  row: TreeRow,
  filters: Filters,
  feuilleSaisie: BaseFeuilleSaisie,
  withLibelle: boolean = false,
) : any => {
  let parent = row;
  // on prend la depth + 1 pour compter le premier niveau (tab)
  const initialDepth = ((getSymbol(row, TreeSymbol.DEPTH) ?? 0) + 1);

  let depth = initialDepth;
  // On prends notre item actuel, et on lui rajoute ses enfants
  const fetchFilters = {
    ...filters,
  };

  while (parent !== undefined) {
    const nodes = feuilleSaisie.nodes[depth];
    // eslint-disable-next-line no-loop-func
    nodes.forEach((node) => {
      if (node.code !== TypeRegroupement.IMPUTATION) {
        fetchFilters[node.code] = parent[node.codeColumnName];
        if (withLibelle) {
          fetchFilters[node.libelleColumnName] = parent[node.libelleColumnName];
        }
      }
    });
    depth--;
    parent = getSymbol(parent, TreeSymbol.PARENT);
  }

  return fetchFilters;
};

export const renderTreeToggle = (rowData: TreeRow, isExpanded: boolean, codeLoading: string) => (
  <Icon
    color="blue"
    data-testid={`treeToggle${rowData?.keyOrder}`}
    loading={rowData.key === codeLoading}
    name={rowData.key === codeLoading
      ? 'circle notch' : `chevron ${isExpanded ? 'down' : 'right'}`}
  />
);
