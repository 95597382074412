/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Button,
  Checkbox,
  Combobox,
  DragDrop,
  Form,
  Modal,
  Segment,
} from '@jvs-group/jvs-mairistem-composants';
import { isNil, omit } from 'lodash';
import { toast } from 'react-toastify';
import type { Exercice } from '@jvs-group/jvs-mairistem-comptabilite';
import type FeuilleSaisie from '../../../FeuilleSaisie/interfaces/feuilleSaisie';
import FEUILLES_SAISIES from '../../../FeuilleSaisie/constants/feuillesSaisies';
import TypeRegroupementFactory from '../../../FeuilleSaisie/classes/Regroupement/TypeRegroupementFactory';
import TypeRegroupement from '../../../FeuilleSaisie/constants/typeRegroupement';
import './NoeudFeuilleModal.less';

interface NoeudFeuilleModalProps {
  feuilleSaisie: FeuilleSaisie,
  onFeuilleSave: any,
  onClose: any,
  exercice: Exercice,
}

const NoeudFeuilleModal = (
  {
    feuilleSaisie: feuilleSaisieProps = null,
    onFeuilleSave = null,
    onClose = null,
    exercice = null,
  } : NoeudFeuilleModalProps,
) => {
  const [feuilleSaisie, setFeuilleSaisie] = React.useState(feuilleSaisieProps);

  React.useEffect(() => {
    setFeuilleSaisie(feuilleSaisieProps);
  }, [feuilleSaisieProps]);

  const feuilleClass = FEUILLES_SAISIES.find((f) => f.code === feuilleSaisie?.code);

  const renderChapitreArticleLabel = () => {
    if (feuilleSaisie?.noeuds.find((n) => n.code === TypeRegroupement.ARTICLE)) {
      return 'Chapitre / Article';
    }

    return 'Chapitre';
  };

  const renderNode = (item) => {
    const typeRegroupement = TypeRegroupementFactory.newInstance(item.code);
    return (
      <Segment
        {...omit(item, ['dragProps'])}
        {...item.dragProps.dragHandle}
        {...item.dragProps.draggable}
      >
        {typeRegroupement?.code === TypeRegroupement.CHAPITRE
          ? renderChapitreArticleLabel() : typeRegroupement?.libelle}
      </Segment>
    );
  };

  const renderFirstNode = () => {
    const typeRegroupement = TypeRegroupementFactory.newInstance(feuilleClass?.nodes[0][0]?.code);

    let libelle = typeRegroupement?.libelle;

    if (typeRegroupement?.code === TypeRegroupement.SENS) libelle += ' (dépense/recette)';

    return (
      <span className="bold">{` "${libelle}" `}</span>
    );
  };

  /**
   * Permet de récupérer uniquement les noeuds visible pour l'utilisateur en
   * excluant ventilation/fonction/analytique si l'utilisateur ne le gère pas.
   */
  const getDragDropItems = (feuille: FeuilleSaisie) => feuille?.noeuds?.filter((noeud) => {
    const typeRegroupement = TypeRegroupementFactory.newInstance(noeud?.code);

    return (typeRegroupement.code !== TypeRegroupement.VENTILATION
              || (exercice?.gestionVentilation && !exercice?.venExec))
    && (typeRegroupement.code !== TypeRegroupement.ANALYTIQUE
      || (exercice?.gestionAnalytique && !exercice?.anaExec))
    && (typeRegroupement.code !== TypeRegroupement.FONCTION || exercice?.gestionFonctionnelle)
    && typeRegroupement.code !== TypeRegroupement.ARTICLE;
  });

  const handleDragEnd = async (result) => {
    // On copie notre feuille
    const feuille = { ...feuilleSaisie };

    // On recupere nos items dans le dragDrop
    const dragDropItems = getDragDropItems(feuille);

    // On recupere les indexs source/destination dans notre liste de noeud
    let indexDestination = 0;
    let indexSource = 0;
    let ordreDestination = 0;

    feuille.noeuds.forEach((n, i) => {
      switch (n.ordre) {
        case dragDropItems[result.destination.index].ordre:
          indexDestination = i;
          // On recupere l'ordre du noeud de destination qui va permettre de changer l'ordre du noeud source a la fin
          ordreDestination = n.ordre;
          break;
        case dragDropItems[result.source.index].ordre:
          indexSource = i;
          break;
        default:
          break;
      }
    });

    // On regarde si on monte ou on descend
    const onMonte = indexSource > indexDestination;

    // Si l'index de destination est le noeud chapitre et qu'on descend
    if (feuille.noeuds[indexDestination].code === TypeRegroupement.CHAPITRE && !onMonte) {
      // On prend le noeud suivant (article)
      indexDestination += 1;
      // On prend aussi son ordre
      ordreDestination += 1;
    }

    // On regarde si le noeud source est le noeud chapitre
    const isSourceChapitre = feuille.noeuds[indexSource].code === TypeRegroupement.CHAPITRE;

    // Si on remonte dans notre dragDrop ou si on descend, le decalage sera soit positif, soit negatif
    const decalage = onMonte ? 1 : -1;
    let decalageWithChapArt = decalage;

    // Si nous déplaçons le noeud chapitre
    if (isSourceChapitre) {
      // On va changer l'ordre par 2 car nous deplacons aussi le noeud article
      decalageWithChapArt += decalage;
    }

    // On boucle sur les noeuds de notre feuille pour changer leur ordre
    if (onMonte && isSourceChapitre) {
      for (let i = (indexSource - 1); i >= indexDestination; i--) {
        // 2 Car nous avons le chapitre et l'article
        feuille.noeuds[i].ordre += 2;
      }
    } else {
      // eslint-disable-next-line max-len
      for (let i = (indexSource - decalageWithChapArt); onMonte ? (i >= indexDestination) : (i <= indexDestination); i -= decalage) {
        feuille.noeuds[i].ordre += decalageWithChapArt;
      }
    }

    // Si le noeud selectionne est chapitre alors on veut aussi changer l'ordre du noeud article
    if (isSourceChapitre) {
      const newOrdreDestination = onMonte ? ordreDestination : (ordreDestination - 1);
      feuille.noeuds[indexSource].ordre = newOrdreDestination;
      feuille.noeuds[indexSource + 1].ordre = newOrdreDestination + 1;
    } else { // Sinon on change juste l'ordre du noeud qu'on a selectionne
      feuille.noeuds[indexSource].ordre = ordreDestination;
    }

    // On trie par ordre
    feuille.noeuds = feuille.noeuds.sort((a, b) => (a.ordre - b.ordre));

    // On applique les modifications
    setFeuilleSaisie(feuille);
  };

  const handleFeuilleSave = () => {
    if (feuilleSaisie.codeFinArbo) {
      onFeuilleSave?.(feuilleSaisie);
    } else {
      toast.error('Le champ regroupement est obligatoire');
    }
  };

  const handleReset = () => {
    const nodes = [];
    const defautltNodes = feuilleClass.nodes.flat();

    for (let i = 1; i < defautltNodes.length - 1; i++) {
      nodes.push({
        ...feuilleSaisie.noeuds[i - 1],
        code: defautltNodes[i]?.code,
        ordre: i - 1,
      });
    }

    setFeuilleSaisie((old) => ({
      ...old,
      codeFinArbo: feuilleClass.defaultCodeFinArbo,
      chapitreArticleLie: feuilleClass.chapitreArticleLie,
      noeuds: nodes,
    }));
  };

  const renderOptions = () => feuilleSaisie?.noeuds.reduce((prev, next) => {
    if ((next?.code !== TypeRegroupement.VENTILATION || (exercice?.gestionVentilation && !exercice?.venExec))
      && (next?.code !== TypeRegroupement.ANALYTIQUE || (exercice?.gestionAnalytique && !exercice?.anaExec))
      && (next?.code !== TypeRegroupement.FONCTION || exercice?.gestionFonctionnelle)
      && (next?.code !== TypeRegroupement.ARTICLE || !feuilleSaisie?.chapitreArticleLie)
    ) {
      const typeRegroupement = TypeRegroupementFactory.newInstance(next?.code, feuilleSaisie.chapitreArticleLie);

      prev.push(
        {
          key: next.code,
          value: next.code,
          text: typeRegroupement.libelle,
        },
      );
    }

    return prev;
  }, []);

  const handleChangeLiaisonChapitre = (e, { checked }) => {
    setFeuilleSaisie((f) => ({
      ...f,
      chapitreArticleLie: checked,
      codeFinArbo: f.codeFinArbo === TypeRegroupement.ARTICLE ? TypeRegroupement.CHAPITRE : f.codeFinArbo,
    }));
  };

  const handleChangeCodeFinArbo = (e, { value }) => {
    setFeuilleSaisie((f) => ({
      ...f,
      codeFinArbo: value,
    }));
  };

  return (
    <Modal
      closeIcon
      onClose={onClose}
      open={!isNil(feuilleSaisie)}
      size="tiny"
    >
      <Modal.Header>
        Feuille de saisie &quot;
        {feuilleClass?.libelle}
        &quot;
      </Modal.Header>
      <Modal.Content>
        <Form className="regroupements">
          <Segment basic compact>
            L'élément
            {renderFirstNode()}
            structure cette feuille de saisie. Il ne peut pas être déplacé.
          </Segment>

          <Segment
            basic
            content="Ordonner l'affichage des autres éléments des lignes budgétaires (par glisser-déposer) :"
          />

          <DragDrop
            items={getDragDropItems(feuilleSaisie)}
            itemControl={renderNode}
            onDragEnd={handleDragEnd}
          />

          <Segment
            basic
            content="Basculer d'une présentation arborescente à une présentation en liste à partir de l'élément :"
          />

          <Form.Field
            control={Combobox}
            fluid
            menuPortalTarget={document.body}
            onChange={handleChangeCodeFinArbo}
            options={renderOptions()}
            selection
            value={feuilleSaisie?.codeFinArbo}
          />

          <Checkbox
            checked={feuilleSaisie?.chapitreArticleLie}
            className="liaisonChapitre"
            label="Ne pas représenter le chapitre pour les comptes réels"
            onChange={handleChangeLiaisonChapitre}
            toggle
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          basic
          content="Réinitialiser la configuration initiale"
          data-testid="resetButton"
          onClick={handleReset}
        />
        <Button
          content="Valider"
          data-testid="confirmButton"
          onClick={handleFeuilleSave}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default NoeudFeuilleModal;
