import React from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Checkbox,
  Message,
  Table,
} from '@jvs-group/jvs-mairistem-composants';
import { Icon, formatMontant, getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import ReactDropzone from 'react-dropzone';
import readXlsxFile from 'read-excel-file';
import { sendImportExcel } from '../../services/outil';
import type Simulation from '../../../Simulation/interfaces/simulation';
import './ImportExcel.less';

interface Montants {
  depenseFonctionnement: number,
  depenseInvestissement: number,
  recetteFonctionnement: number,
  recetteInvestissement: number,
}

interface ImportExcelProps {
  loading: boolean;
  onValidate: (data?: unknown) => void,
  simulation: Simulation;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImportExcel = ({
  loading,
  onValidate,
  simulation,
  setLoading,
}: ImportExcelProps) => {
  const [data, setData] = React.useState<Montants>();
  const [errors, setErrors] = React.useState<string[]>([]);
  const [file, setFile] = React.useState<File>();
  const [loadingImport, setLoadingImport] = React.useState<boolean>(false);
  const [substitute, setSubstitute] = React.useState<boolean>(false);

  const handleDrop = (files: File[]) => setFile(files[0]);

  const handleImport = async () => {
    try {
      setLoadingImport(true);
      const formData = new FormData();
      formData.append('excel', file);
      await sendImportExcel(simulation?.identifiant, formData, substitute);
      setErrors([]);
      toast.success('Import terminé avec succès');
      onValidate?.();
    } catch (e) {
      // Si l'erreur est metier
      if (e.code === 400) {
        setErrors(e.message.map((error) => error.message[0]));
      } else { // Si l'erreur est serveur
        toast.error(getErrorMessage(e, "Erreur lors de l'import"));
      }
    } finally {
      setLoadingImport(false);
    }
  };

  const getMontants = async () => {
    try {
      setLoading(true);
      // On calcule les depenses/recettes de fonctionnement/investissement
      const rows = await readXlsxFile(file);

      let indexPropColumn: number;
      let indexSectionColumn: number;
      let indexSensColumn: number;

      const montants = {
        depenseFonctionnement: 0,
        depenseInvestissement: 0,
        recetteFonctionnement: 0,
        recetteInvestissement: 0,
      };

      // On parcourt la premiere ligne du excel pour savoir les colonnes
      for (let i = 0; i < rows[0].length; i++) {
        const excelHeader = rows[0][i];
        if (excelHeader === 'Proposition') indexPropColumn = i;
        if (excelHeader === 'Section') indexSectionColumn = i;
        if (excelHeader === 'Sens') indexSensColumn = i;
        if (indexPropColumn !== undefined && indexSectionColumn !== undefined && indexSensColumn !== undefined) break;
      }

      // On parcourt le reste des lignes pour avoir le montant final
      for (let i = 1; i < rows.length; i++) {
        const imputation = rows[i];
        const montant = Number(imputation[indexPropColumn]);
        const section = imputation[indexSectionColumn];
        const sens = imputation[indexSensColumn];

        if (sens === 'D') {
          if (section === 'F') {
            montants.depenseFonctionnement += montant;
          } else if (imputation[indexSectionColumn] === 'I') {
            montants.depenseInvestissement += montant;
          }
        } else if (sens === 'R') {
          if (section === 'F') {
            montants.recetteFonctionnement += montant;
          } else if (section === 'I') {
            montants.recetteInvestissement += montant;
          }
        }
      }

      setData(montants);
    } catch {
      toast.error('Erreur lors du calcul des montants');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e, { checked }: { checked : boolean }) => setSubstitute(checked);

  const handleReset = () => {
    setData(undefined);
    setErrors([]);
    setFile(undefined);
  };

  React.useEffect(() => {
    if (file) getMontants();
  }, [file]);

  return (
    <>
      {data ? (
        <>
          <Table celled definition>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell textAlign="center">Fonctionnement</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Investissement</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell
                  collapsing
                  content="Total dépenses"
                  negative
                  textAlign="center"
                  verticalAlign="middle"
                />
                <Table.Cell content={formatMontant(data.depenseFonctionnement)} />
                <Table.Cell content={formatMontant(data.depenseInvestissement)} />
              </Table.Row>
              <Table.Row>
                <Table.Cell
                  collapsing
                  content="Total recettes"
                  positive
                  textAlign="center"
                  verticalAlign="middle"
                />
                <Table.Cell content={formatMontant(data.recetteFonctionnement)} />
                <Table.Cell content={formatMontant(data.recetteInvestissement)} />
              </Table.Row>
            </Table.Body>
          </Table>

          {errors.length > 0 ? (
            <Message
              error
              header="Votre tableur comporte des erreurs:"
              list={errors}
            />
          ) : (
            <Checkbox
              checked={substitute}
              label={
                `Remplacer les propositions déjà renseignées par celles de la feuille Excel
                (par défaut elles sont cumulées)`
              }
              onChange={handleChange}
              toggle
            />
          )}
        </>
      ) : (
        <ReactDropzone
          accept={{
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
          }}
          onDrop={handleDrop}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="importExcelDropzone" {...getRootProps()}>
              <input {...getInputProps()} />
              <Icon iconSet="Lucide" name="FolderUp" />
              <p>Déposer ici un classeur</p>
            </div>
          )}
        </ReactDropzone>
      )}
      <div>
        {errors.length === 0 && (
          <Button
            content="Mettre à jour les prévisions"
            data-testid="importButton"
            disabled={!data || loading || loadingImport}
            floated="right"
            loading={loading || loadingImport}
            onClick={handleImport}
            positive
          />
        )}
        {data && (
          <Button
            content={errors.length > 0 ? 'Redéposer un autre fichier' : "Réinitialiser l'import"}
            floated="right"
            onClick={handleReset}
          />
        )}
      </div>
    </>
  );
};

export default ImportExcel;
