import React from 'react';
import { Form, Segment } from '@jvs-group/jvs-mairistem-composants';
import { getSimulation } from '../utils/simulation';
import type Simulation from '../interfaces/simulation';
import Panels from '../../Panels/components/Panels';
import TreeView from '../../FeuilleSaisie/components/TreeView';
import type { TreeRow } from '../../FeuilleSaisie/interfaces/treeRow';
import BoiteOutil from './BoiteOutil';
import TypePeriode from '../enums/typePeriode';
import type PanelsData from '../../Panels/interfaces/panelsData';
import SessionContext from '../../../components/Context/SessionContext';
import TypeBudget from '../enums/typeBudget';
import ShowOnly from '../../FeuilleSaisie/constants/showOnly';
import type Filters from '../interfaces/filters';
import SimulationAction from './SimulationAction';
import './ElaborationBudget.less';
import type ParametresSimulation from '../interfaces/parametresSimulation';

interface ElaborationBudgetProps {
  identifiantSimulation: number;
}

const ElaborationBudget = ({ identifiantSimulation }: ElaborationBudgetProps) => {
  const { userRole } = React.useContext(SessionContext);
  const [filters, setFilters] = React.useState<Filters>();
  const [simulation, setSimulation] = React.useState<Simulation>(null);

  const getDefaultPeriode = (simulation : Simulation) => {
    if (simulation?.budget?.type === TypeBudget.COMPTE_ADMINISTRATIF) {
      return TypePeriode.CA_CFU;
    }

    return simulation?.vote ? TypePeriode.VOTE : TypePeriode.PROPOSE;
  };

  const fetchSimulation = async (identifiantSimulation: number, onlyPanelData: boolean = false) => {
    const s = await getSimulation(identifiantSimulation);

    // Remise a zero des filtres
    setFilters({ showOnly: ShowOnly.TOUTES_LIGNES });

    if (onlyPanelData) {
      setSimulation((old) => ({
        ...old,
        panelData: s.panelData,
      }));
    } else {
      setSimulation({
        ...s,
        typePeriode: getDefaultPeriode(s),
      });
    }
  };

  const handleRefreshSimulation = (
    onlyPanelData: boolean = false,
  ) => fetchSimulation(Number(identifiantSimulation), onlyPanelData);

  // Récupération de l'identifiant de simulation dans l'url
  React.useEffect(() => {
    handleRefreshSimulation();
  }, []);

  const handleChangeImputation = (panelDataChange: PanelsData) => {
    const panelData : PanelsData = {
      prop: {},
      demande: {},
      vote: {},
      exercicePrecedent: {},
      exercicePrecedent2: {},
      ca: {},
    };
    Object.keys(simulation.panelData).forEach((typePeriode) => {
      Object.keys(simulation.panelData[typePeriode]).forEach((key) => {
        if (typeof simulation.panelData[typePeriode][key] === 'object'
          && simulation.panelData[typePeriode][key] !== null) {
          // on ne met pas a jour les annees precedentes
          panelData[typePeriode][key] = simulation.panelData[typePeriode][key];
        } else {
          // eslint-disable-next-line max-len
          panelData[typePeriode][key] = (simulation.panelData[typePeriode][key] ?? 0) + (panelDataChange?.[typePeriode]?.[key] ?? 0);
        }
      });
    });

    setSimulation((s) => ({
      ...s,
      panelData,
    }));
  };

  const handleChangeMontant = (differencesMontants: any, imputation: TreeRow) => {
    const panelDataChanges : PanelsData = { prop: {}, demande: {}, vote: {} };

    Object.keys(differencesMontants).forEach((typePeriode) => {
      if (imputation.imp_sens === 'D') {
        if (imputation.cha_section === 'I') {
          panelDataChanges[typePeriode].depenseInvestissement = differencesMontants[typePeriode];
        } else {
          panelDataChanges[typePeriode].depenseFonctionnement = differencesMontants[typePeriode];
          if (simulation?.budget?.type !== TypeBudget.COMPTE_ADMINISTRATIF
            && imputation.cha_nat_bud === 'R'
            && !imputation.art_code?.startsWith('002')) {
            panelDataChanges[typePeriode].depenseReelFonctionnement = differencesMontants[typePeriode];

            if (!imputation.art_code?.startsWith('002')) {
              panelDataChanges[typePeriode].epargneBrute = -differencesMontants[typePeriode];
            }
          }
        }

        if (simulation?.budget?.type !== TypeBudget.COMPTE_ADMINISTRATIF && imputation.cha_code === '012') {
          panelDataChanges[typePeriode].montantChargePersonnel = differencesMontants[typePeriode];
        }

        if (simulation?.budget?.type !== TypeBudget.COMPTE_ADMINISTRATIF
          && imputation.cha_nat_bud === 'R'
          && imputation.art_code?.startsWith('1641')) {
          panelDataChanges[typePeriode].remboursementCapitalDette = differencesMontants[typePeriode];
        }
      } else if (imputation?.imp_sens === 'R') {
        if (imputation?.cha_section === 'I') {
          panelDataChanges[typePeriode].recetteInvestissement = differencesMontants[typePeriode];
        } else {
          panelDataChanges[typePeriode].recetteFonctionnement = differencesMontants[typePeriode];

          if (imputation.cha_nat_bud === 'R' && !imputation.art_code?.startsWith('002')) {
            panelDataChanges[typePeriode].recetteReelFonctionnement = differencesMontants[typePeriode];
          }

          if (!imputation.art_code?.startsWith('002')
          && !imputation.art_code?.startsWith('775')
          && !simulation?.transfertCpta
          && simulation?.budget?.type !== TypeBudget.COMPTE_ADMINISTRATIF
          && imputation.cha_nat_bud === 'R') {
            panelDataChanges[typePeriode].epargneBrute = differencesMontants[typePeriode];
          }
        }
      }
    });

    handleChangeImputation(panelDataChanges);
  };

  const handleChangePeriode = async (typePeriode: TypePeriode) => {
    setSimulation((old) => ({
      ...old,
      typePeriode,
    }));
  };

  const handleChangeSimulation = (data: ParametresSimulation) => {
    setSimulation((old) => ({ ...old, ...data }));
  };

  return (
    <Segment basic secondary>
      <Form>
        <Form.Group>
          <Form.Field
            control={SimulationAction}
            onChangePeriode={handleChangePeriode}
            onRefresh={handleRefreshSimulation}
            onSave={handleChangeSimulation}
            simulation={simulation}
            userRole={userRole}
            width={4}
          />
          <Form.Field
            control={BoiteOutil}
            onRefresh={handleRefreshSimulation}
            simulation={simulation}
            userRole={userRole}
            width={12}
          />
        </Form.Group>
      </Form>

      <Panels simulation={simulation} />

      <TreeView
        filters={filters}
        onChangeMontant={handleChangeMontant}
        onRefresh={handleRefreshSimulation}
        setFilters={setFilters}
        simulation={simulation}
        userRole={userRole}
      />
    </Segment>
  );
};

export default ElaborationBudget;
