import React from 'react';
import {
  Button,
  Checkbox,
  Dimmer,
  Loader,
  Modal,
  type CheckboxProps,
} from '@jvs-group/jvs-mairistem-composants';
import { getErrorMessage } from '@jvs-group/jvs-mairistem-finances-utils';
import { toast } from 'react-toastify';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import type OutilModalProps from '../../interfaces/OutilModalProps';
import { getControleCoherence } from '../../services/outil';
import type BaseControleCoherence from '../../classes/ControleCoherence/BaseControleCoherence';
import ControleCoherenceFactory from '../../classes/ControleCoherence/ControleCoherenceFactory';
import ControleCoherenceList from './ControleCoherenceList';
import StatutControleCoherence from '../../enums/StatutControleCoherence';
import './ControleCoherenceModal.less';

const ControleCoherenceModal = ({
  onClose,
  open,
  simulation,
}: OutilModalProps) => {
  const [controlesListAll, setControlesListAll] = React.useState<BaseControleCoherence[][]>([]);
  const [controlesListAnomalies, setControlesListAnomalies] = React.useState<BaseControleCoherence[][]>([]);
  const [downloading, setDownloading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [onlyAnomalie, setOnlyAnomalie] = React.useState<boolean>(true);

  const handleCheckboxChange = (e: React.MouseEvent<HTMLInputElement>, { checked }: CheckboxProps) => {
    setOnlyAnomalie(checked);
  };

  const handleCompteRendu = () => {
    let content = '';
    const list = onlyAnomalie ? controlesListAnomalies : controlesListAll;
    list.forEach((familleAnomalie) => {
      content += `***${familleAnomalie[0].getFamille()}***\n\n`;
      familleAnomalie.forEach((anomalie) => {
        content += `${anomalie.getLibelleWithStatut()}\n`;
        content += `${anomalie.getExplicatif(simulation.budget.type)}\n\n`;
      });
    });
    const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
    const filename = [
      'controles',
      `${simulation?.budget?.type}${simulation?.budget?.dm ? simulation.budget.dm.num : ''}`,
      simulation?.exercice?.anneeExercice,
      simulation?.exercice?.entite?.entite?.libelle,
      dayjs().format('YYYYMMDDHHmmss'),
    ];
    saveAs(blob, `${filename.join('-')}.txt`);
  };

  const handleClose = () => onClose?.();

  const handleDownload = () => {
    try {
      setDownloading(true);
      handleCompteRendu();
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors du téléchargement du compte-rendu'));
    } finally {
      setDownloading(false);
    }
  };

  const handleFetchControleCoherence = async () => {
    try {
      const controlesClass = ControleCoherenceFactory.newInstances(await getControleCoherence(simulation));
      setControlesListAll(values(groupBy(controlesClass, 'famille')));
      setControlesListAnomalies(values(groupBy(
        controlesClass.filter((controle) => controle.statut !== StatutControleCoherence.OK),
        'famille',
      )));
    } catch (e) {
      toast.error(getErrorMessage(e, 'Erreur lors de la récupération des montants du virement de crédit'));
      onClose?.();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      closeIcon
      onClose={handleClose}
      onMount={handleFetchControleCoherence}
      open={open}
      size="large"
    >
      <Modal.Header content="Contrôle de la cohérence des prévisions" />
      <Modal.Content className="controleCoherenceModalContent">
        {
          loading ? (
            <Dimmer active inverted>
              <Loader size="massive" />
            </Dimmer>
          ) : (
            <>
              <Checkbox
                checked={onlyAnomalie}
                label="Afficher uniquement les points en anomalie"
                onChange={handleCheckboxChange}
                toggle
              />
              <ControleCoherenceList
                controlesList={onlyAnomalie ? controlesListAnomalies : controlesListAll}
                typeBudget={simulation.budget.type}
              />
            </>
          )
        }
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Télécharger le compte-rendu"
          data-testid="downloadButton"
          disabled={loading}
          loading={downloading}
          onClick={handleDownload}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ControleCoherenceModal;
