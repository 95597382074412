import React from 'react';
import { Grid, type IconJVSProps } from '@jvs-group/jvs-mairistem-composants';
import { Icon } from '@jvs-group/jvs-mairistem-finances-utils';
import './Outil.less';

interface OutilProps {
  icon: IconJVSProps;
  title: string;
}

const Outil = ({
  icon,
  title,
}: OutilProps) => (
  <Grid className="outil">
    <Grid.Row className="outilRow">
      <Grid.Column width={2}>
        <Icon
          size={28}
          {...icon}
        />
      </Grid.Column>
      <Grid.Column width={14}>
        {title}
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default Outil;
